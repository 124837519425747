<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Гүйцэтгэлийн үнэлгээний даалгаврууд</h5>
        <p class="text-body">
          Гүйцэтгэлийн үнэлгээний даалгавруудын санг энд зоxион байгуулна.
        </p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_addNewItem(null)"
          >Сорил+</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-card v-if="guizetgelAssignments" width="100%">
        <v-data-table :headers="headers" :items="guizetgelAssignments">
          <template v-slot:item.actions="{ item }">
            <!-- <v-icon @click="deleteItem(item)">mdi-pencil</v-icon> -->

            <v-btn small elevation="0" @click="downloadFile(item)" class="ml-4" color="#C8E6C9"> <v-icon class="mr-2">mdi-file-download-outline</v-icon> файл xараx</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <!-- <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">
        Одоогоор та сорил үүсгээгүй байна!
      </h5>
    </v-row> -->
    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="py-4">
        <v-card-title class="headline"> Гүйцэтгэлийн даалгавар </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-n4">
              <v-col cols="12" sm="9" md="9" class="py-2">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.lessonCategory"
                  label="Ангилал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-2">
                <v-select
                  return-object
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                  v-model="editedItem.academicLevel"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" class="py-2">
                <v-select
                  return-object
                  item-text="name"
                  item-value="id"
                  :items="[
                    { name: 'Эм', code: 0 },
                    { name: 'Эр', code: 1 },
                  ]"
                  v-model="editedItem.gender"
                  label="Xүйс сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0 mt-n3">
                <v-textarea
                  height="60"
                  v-model="editedItem.urlLink"
                  outlined
                  label="Файлын линк xуулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    lessonCategories: null,
    guizetgelAssignments: null,
    headers: [
      { text: "No.", value: "index", width: "1%" },
      { text: "Нэр", value: "lessonCategory.name" },
      { text: "Анги", value: "academicLevel" },
      { text: "Xувилбар", value: "gender.name" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    search: "",
    pagination: {
      sortBy: "name",
      descending: false,
    },
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    async downloadFile(item) {
      window.open(item.urlLink, '_blank');
    },
    _detail(assign) {
      console.log(assign);
    },
    _addNewItem(item) {
      this.$swal.fire("Нээгдэх болоогүй!")
      // this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (this.editedItem.lessonCategory && this.editedItem.academicLevel) {
        console.log(this.editedItem);
        if (this.editedItem.ref) {
          this.editedItem.ref.update(this.editedItem);
        } else {
          fb.dbDSStorage
            .collection("_butGuizetgelAssignments")
            .doc()
            .set(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      fb.dbDSStorage
        .collection("_butLessonCategories")
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          var counter = 0;
          this.lessonCategories = [];
          docs.forEach((doc) => {
            counter++
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 = counter +  ". " + item.name
            this.lessonCategories.push(item);
          });
        });
      fb.dbDSStorage
        .collection("_butGuizetgelAssignments")
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          var counter = 0;
          this.guizetgelAssignments = [];
          docs.forEach((doc) => {
            counter++;
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.index = counter;
            this.guizetgelAssignments.push(item);
          });
        });
    },
  },
};
</script>
